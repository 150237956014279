import { useState } from 'react';

import {
  autoUpdate,
  flip,
  limitShift,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import DownloadAppButton from 'ui/download-app-button/download-app-button';

import QRCodeIcon from 'icons/main/qr-code-icon.svg';

import styles from './qr-download-btn.module.scss';

const QrDownloadBtn = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { i18n } = useTranslation('common');

  const isArLang = i18n.language === 'ar' || i18n.language === 'fa';

  const { refs, floatingStyles, update } = useFloating({
    middleware: [
      offset(10),
      flip(),
      shift({
        limiter: limitShift({ offset: 10 }),
        padding: 16,
      }),
    ],
    open: isOpen,
    placement: 'bottom',
    strategy: 'absolute',
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      <button
        ref={refs.setReference}
        className={styles.icon}
        onClick={() => {
          setIsOpen(!isOpen);
          update();
        }}
      >
        <QRCodeIcon />
        {isOpen && (
          <div
            ref={refs.setFloating}
            className={clsx(styles.qr, { [styles.qr_ar]: isArLang })}
            style={{
              ...floatingStyles,
            }}
          >
            <DownloadAppButton />
          </div>
        )}
      </button>
    </>
  );
};

export default QrDownloadBtn;
