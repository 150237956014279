import React from 'react';
import type { ImageProps } from 'next/image';
import Image from 'next/image';

import clsx from 'clsx';
import { IconWrapper, Typography } from 'cryptomus-aurora-kit';

import UserIcon from 'public/icons/header/user-avatar.svg';

import styles from './user-avatar.module.scss';

interface IUserAvatarProps {
  size: 'small' | 'medium' | 'big';
  className?: string;
  login?: string;
  src?: string;
}

const sizes = {
  big: 44,
  medium: 40,
  small: 32,
};

const UserAvatar: React.FC<
  IUserAvatarProps & Omit<Omit<ImageProps, 'src'>, 'width' | 'height'>
> = ({ src, className, size, login, ...props }) => {
  const getLinSymbol = login?.includes('@') ? login[0] : undefined;

  return (
    <div className={clsx(styles.avatar_wrapper, styles[size], className)}>
      {src ? (
        <Image
          height={sizes[size]}
          quality={100}
          src={src}
          width={sizes[size]}
          {...props}
          alt='user_avatar'
        />
      ) : (
        <IconWrapper appearance='select' size='medium'>
          {getLinSymbol ? (
            <Typography as='span' fontWeight={400} variant='system_h2'>
              {getLinSymbol.toUpperCase()}
            </Typography>
          ) : (
            <UserIcon />
          )}
        </IconWrapper>
      )}
    </div>
  );
};

export default UserAvatar;
