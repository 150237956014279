import Image from 'next/image';

import { Typography } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import styles from './notifications-empty.module.scss';

const NotificationsEmpty = () => {
  const { t } = useTranslation('main');

  return (
    <div className={styles.emptyList}>
      <Image
        alt="You don't have notification"
        height={138}
        src='/public/img/common/error-illustration.png'
        width={138}
      />
      <Typography as='p' fontWeight={400} variant='system_h4'>
        {t("You don't' have notifications")}
      </Typography>
    </div>
  );
};

export default NotificationsEmpty;
