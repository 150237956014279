import Image from 'next/image';

import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';

import DownloadButtons from 'ui/download-buttons/download-buttons';

import QRCodeIcon from 'icons/main/app-qr-code-icon.svg';

import styles from './access-block.module.scss';

const AccessBlock = () => {
  const { t } = useTranslation('main');

  const isTablet = useMediaQuery('(max-width: 768px)');

  return isTablet ? (
    <div className={styles.mobile_container}>
      <h2>{t('Access your crypto assets from any device')}</h2>

      <div className={styles.mobile_container__image_container}>
        <Image
          alt='phone with trading'
          className={styles.mobile_container__mask}
          height={418}
          quality={100}
          src='/img/main/phone.png'
          width={204}
        />
      </div>

      <div className={styles.mobile_container__info}>
        <h3>
          {t('Use all the features of the service in the mobile application')}
        </h3>

        <div className={styles.mobile_container__info__links}>
          <DownloadButtons
            className={styles.downloadButtons}
            googleHeight={40}
            googleWidth={137}
            isQr={false}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <Image
        alt='phone with trading'
        height={514}
        quality={100}
        src='/img/main/phone.png'
        width={250}
      />

      <div className={styles.container__info}>
        <h2>{t('Access your crypto assets from any device')}</h2>

        <h3>
          {t('Use all the features of the service in the mobile application')}
        </h3>

        <div className={styles.container__info__links}>
          <div className={styles.container__info__links__qr}>
            <QRCodeIcon />
            <span>
              {t('The app is available in the App Store and Google Play')}
            </span>
          </div>

          <div className={styles.container__info__links__stores}>
            <DownloadButtons googleHeight={40} googleWidth={137} isQr={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessBlock;
