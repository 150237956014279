import Cookies from 'js-cookie';

//!!!!ACHTUNG!!!
//Это ГЛОБАЛЬНАЯ смена языка, она унифицирована в каждом приложении
//!!!Если меняешь здесь - поменяй во всех сервисах тоже!!!

const domain_name = 'cryptomus.com';

export const getLanguageFromCookie = () => Cookies.get('i18next');

export const changeGlobalLanguage = (language: string) => {
  const expirationDate = new Date();

  expirationDate.setFullYear(expirationDate.getFullYear() + 10);

  if (globalThis.location.host.includes(domain_name)) {
    Cookies.set('i18next', language, {
      domain: domain_name,
      expires: expirationDate,
    });
  } else {
    Cookies.set('i18next', language, { expires: expirationDate });
  }
};
