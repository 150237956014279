import React from 'react';

import GetStartedButton from './get-started-button/get-started-button';
import SettingsMenu from './settings-menu/settings-menu';

import styles from './unauth-items.module.scss';

export interface UnAuthItemsProps {}

const UnAuthItems: React.FC<UnAuthItemsProps> = () => (
  <div className={styles.un_auth_items__wrapper}>
    <GetStartedButton />
    <div className={styles.divider} />
    <SettingsMenu />
  </div>
);

export default UnAuthItems;
