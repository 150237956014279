import React from 'react';

import clsx from 'clsx';
import { Button, Typography } from 'cryptomus-aurora-kit';
import dayjs from 'dayjs';

import type { INotificationsItem } from 'components/header/header.types';

import styles from './notification-item.module.scss';

interface INotificationItemProps extends INotificationsItem {
  onClick: () => void;
  // selected?: boolean;
}

const getCreatedAt = (value: string) => dayjs(value).format('DD.MM.YYYY');

const NotificationItem: React.FC<INotificationItemProps> = ({
  title,
  text,
  link,
  link_text,
  read,
  created_at,
  onClick,
  // selected = false,
}) => (
  //TODO ждем бэк
  // const getItemIcon = useMemo(() => {
  //   if (selected) return <CheckIcon />;
  //   else if (icon) return <Image src={icon} alt='Notification image' width={44} height={44} />;
  //   return iconByType[type];
  // }, [selected, icon]);

  <div
    className={clsx(styles.item, { [styles.active]: !read })}
    role='button'
    tabIndex={0}
    onClick={onClick}
  >
    {/* <IconWrapper appearance='select' className={selected ? styles.selected : ''}>
        {getItemIcon}
      </IconWrapper> */}
    <div className={styles.item__wrapper}>
      <Typography
        as='span'
        className={styles.item__date}
        fontWeight={400}
        variant='system_h5'
      >
        {getCreatedAt(created_at)}
      </Typography>
      <Typography as='h5' className={styles.item__title} variant='system_h4'>
        {title}
      </Typography>
      <div
        className={clsx(styles.item__content, {
          [styles.item__content_interactive]: !!link,
        })}
      >
        {React.createElement('div', {
          dangerouslySetInnerHTML: {
            __html: text.replace(/<a/g, '<a class="customFocus"'),
          },
          onClick: (e: MouseEvent) => {
            const target = e?.target as HTMLAnchorElement;

            if (target?.tagName.toLowerCase() === 'a') e.stopPropagation();
          },
        })}
      </div>
      {link_text ? (
        <Button
          appearance='secondary'
          as='a'
          className={clsx(styles.item__button, 'customFocus')}
          href={link ?? ''}
          size='s'
          type='internal'
          onClick={(e) => e.stopPropagation()}
        >
          {link_text}
        </Button>
      ) : (
        ''
      )}
    </div>
  </div>
);

export default NotificationItem;
