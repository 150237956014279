import NotificationSkeleton from './notification-skeleton/notification-skeleton';

import styles from './notification-skeletons.module.scss';

const NotificationSkeletons = () => (
  <div className={styles.skeletons_list}>
    <NotificationSkeleton />
    <NotificationSkeleton />
    <NotificationSkeleton />
  </div>
);

export default NotificationSkeletons;
