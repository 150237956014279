import React from 'react';

import clsx from 'clsx';
import { Typography } from 'cryptomus-aurora-kit';

import CloseIcon from 'public/icons/header/Close.svg';

import styles from './burger-header.module.scss';

interface IBurgerHeaderProps {
  closeBurger: () => void;
  reversed?: boolean;
  title?: string;
}

const BurgerHeader: React.FC<IBurgerHeaderProps> = ({
  closeBurger,
  reversed = false,
  title,
}) => (
  <span
    className={clsx(styles.header, {
      [styles.reversed]: reversed,
      [styles.title]: title,
    })}
  >
    {title && (
      <Typography isIgnoreMediaQuery fontWeight={500} variant='system_h2'>
        {title}
      </Typography>
    )}
    <button className={styles.header__btn} onClick={closeBurger}>
      <CloseIcon />
    </button>
  </span>
);

export default BurgerHeader;
