'use client';

import React from 'react';

import clsx from 'clsx';
import { FiatIcon, Typography } from 'cryptomus-aurora-kit';

import styles from './currency-item.module.scss';

type TSize = 'default' | 'small';

type TVariant = 'system_h4' | 'tag';

type TFontWeight = 400 | 600;

interface ICurrencyItemProps {
  currency: string;
  onClick: () => void;
  symbol: string;
  Tag?: 'button' | 'span';
  hideSymbol?: boolean;
  quickAction?: boolean;
  size?: TSize;
}

const sizeClasses: Record<TSize, string> = {
  ['default']: styles.currency_item_default,
  ['small']: styles.currency_item_small,
};

const sizeVariantProps: Record<TSize, TVariant> = {
  ['default']: 'system_h4',
  ['small']: 'tag',
};

const sizeFontWeightProps: Record<TSize, TFontWeight> = {
  ['default']: 400,
  ['small']: 600,
};

const CurrencyItem: React.FC<ICurrencyItemProps> = ({
  currency,
  symbol,
  onClick,
  size = 'default',
  Tag = 'button',
  hideSymbol = false,
  quickAction = false,
}) => (
  <Tag
    tabIndex={0}
    className={clsx(styles.currency_item, sizeClasses[size], {
      [styles.quickAction]: quickAction,
    })}
    onClick={onClick}
  >
    {/* TODO check symbolCode value (may be it needs to be a currency code) */}
    {!hideSymbol && <FiatIcon symbolCode={symbol} />}

    <Typography
      as='p'
      fontWeight={sizeFontWeightProps[size]}
      variant={sizeVariantProps[size]}
    >
      {currency}
    </Typography>
  </Tag>
);

export default CurrencyItem;
