import type {
  IBurgerMenuItem,
  IHeaderNavItem,
  IItemData,
  INavListData,
  INavListItem,
  IWalletData,
} from './header.types';

import { CRYPTOMUS_APP_URL } from 'constants/app-url';

import BlankIcon from 'public/icons/header/2reports.svg';
import ApiIcon from 'public/icons/header/api.svg';
import ReceiveIcon from 'public/icons/header/arrow-down-left.svg';
import SendIcon from 'public/icons/header/arrow-up-right.svg';
import AwardsIcon from 'public/icons/header/awards.svg';
import BarChartIcon from 'public/icons/header/BarChart.svg';
import OverviewIcon from 'public/icons/header/big_pie_chart.svg';
import BrokerIcon from 'public/icons/header/broker.svg';
import CardIcon from 'public/icons/header/card.svg';
import DollarIcon from 'public/icons/header/currency_dollar.svg';
import DirectoryIcon from 'public/icons/header/directory.svg';
import GiftIcon from 'public/icons/header/Gift.svg';
import GlobeIcon from 'public/icons/header/globe.svg';
import PromotionsIcon from 'public/icons/header/Grid.svg';
import InvoiceIcon from 'public/icons/header/invoice.svg';
import MarketMakerIcon from 'public/icons/header/market-maker.svg';
import MerchantIcon from 'public/icons/header/merchant.svg';
import P2PIcon from 'public/icons/header/P2PTrade.svg';
import PayIcon from 'public/icons/header/pay.svg';
import PluginsIcon from 'public/icons/header/plugins.svg';
import ProccessingIcon from 'public/icons/header/processing.svg';
import ProfileIcon from 'public/icons/header/Profile.svg';
import MassPayoutsIcon from 'public/icons/header/receipt_favourite.svg';
import ReferralsIcon from 'public/icons/header/referal22.svg';
import RefreshIcon from 'public/icons/header/refresh.svg';
import SettingsIcon from 'public/icons/header/settings.svg';
import SpotIcon from 'public/icons/header/spot.svg';
import StakingIcon from 'public/icons/header/staking.svg';
import TradingChartIcon from 'public/icons/header/trading-chart.svg';
import TransferIcon from 'public/icons/header/transfer.svg';
import WalletIcon from 'public/icons/header/wallet3.svg';

export const navListData: INavListData = {
  byCrypto: [
    {
      description: 'BuyCryptoByCardDescription',
      icon: <CardIcon />,
      link: { href: process.env.BASE_URL + '/buy', type: 'external' },
      title: 'BuyCryptoByCardTitle',
    },
    {
      description: 'byCryptoP2PDescription',
      icon: <P2PIcon />,
      link: { href: 'https://p2p.cryptomus.com/', type: 'external' },
      title: 'P2P',
    },
  ],
  earn: [
    {
      description: 'EarnAwardsDescription',
      icon: <AwardsIcon />,
      link: { href: `${CRYPTOMUS_APP_URL}/awards`, type: 'external' },
      title: 'EarnAwardsTitle',
    },
    {
      description: 'EarnRefferalProgramDescription',
      icon: <ReferralsIcon />,
      link: {
        href: `${CRYPTOMUS_APP_URL}/settings/referral`,
        type: 'external',
      },
      title: 'EarnRefferalProgramTitle',
    },
    {
      description: 'AffiliateDescription',
      icon: <ProfileIcon />,
      link: { href: process.env.BASE_URL + '/affiliate', type: 'external' },
      title: 'AffiliateTitle',
    },
    {
      description: 'EarnDescription',
      icon: <DollarIcon />,
      link: { href: process.env.BASE_URL + '/earn', type: 'external' },
      title: 'EarnTitle',
    },
  ],
  more: [
    {
      description: 'MoreMerchantDirectoryDescription',
      icon: <DirectoryIcon />,
      link: { href: 'https://directory.cryptomus.com/', type: 'external' },
      title: 'MoreMerchantDirectoryTitle',
    },
    {
      description: 'MoreAPIDescription',
      icon: <DollarIcon />,
      link: { href: 'https://doc.cryptomus.com/personal', type: 'external' },
      title: 'API',
    },
  ],
  payments: [
    {
      description: 'PaymentGatewayDescription',
      icon: <GlobeIcon />,
      subItems: [
        {
          description: 'PaymentCryptoDescription',
          icon: <PayIcon />,
          link: { href: process.env.BASE_URL + '/gateway', type: 'external' },
          title: 'PaymentCryptoTitle',
        },
        {
          description: 'PaymentsCryptoprocessingDescription',
          icon: <ProccessingIcon />,
          link: {
            href: process.env.BASE_URL + '/processing',
            type: 'external',
          },
          title: 'PaymentsCryptoprocessingTitle',
        },
        {
          description: 'PaymentsWhiteLabelGatewayDescription',
          icon: <ProccessingIcon />,
          link: {
            href: process.env.BASE_URL + '/white_label',
            type: 'external',
          },
          title: 'PaymentsWhiteLabelGatewayTitle',
        },
      ],
      title: 'PaymentGatewayTitle',
    },
    {
      description: 'PaymentMassPayoutsDescription',
      icon: <MassPayoutsIcon />,
      link: { href: process.env.BASE_URL + '/mass-payout', type: 'external' },
      title: 'PaymentMassPayoutsTitle',
    },
    {
      description: 'PaymentPluginsDescription',
      icon: <PluginsIcon />,
      link: {
        href: 'https://doc.cryptomus.com/business/sdks-and-modules/modules',
        type: 'external',
      },
      title: 'PaymentPluginsTitle',
    },
    {
      description: 'PaymentAPIDescription',
      icon: <ApiIcon />,
      link: {
        href: 'https://doc.cryptomus.com/personal',
        type: 'external',
      },
      title: 'PaymentAPITitle',
    },
    {
      description: 'PaymentMerchantDirectoryDescription',
      icon: <DirectoryIcon />,
      link: {
        href: 'https://directory.cryptomus.com/',
        type: 'external',
      },
      title: 'PaymentMerchantDirectoryTitle',
    },
  ],
  trade: [
    {
      description: 'TradeConvertDescription',
      icon: <RefreshIcon />,
      link: { href: process.env.BASE_URL + '/convert', type: 'external' },
      title: 'TradeConvertTitle',
    },
    {
      description: 'TradeSpotDescription',
      icon: <SpotIcon />,
      link: {
        href: process.env.BASE_URL + '/exchange/trading',
        type: 'external',
      },
      title: 'TradeSpotTitle',
    },
    {
      description: 'TradeApiDescription',
      icon: <ApiIcon />,
      link: { href: 'https://doc.cryptomus.com/personal', type: 'external' },
      title: 'TradeApiTitle',
    },
    {
      description: 'TradeBrokerProgramDescription',
      icon: <BrokerIcon />,
      link: { href: process.env.BASE_URL + '/broker', type: 'external' },
      title: 'TradeBrokerTitle',
    },
    {
      description: 'TradeMarketMakerDescription',
      icon: <MarketMakerIcon />,
      link: { href: process.env.BASE_URL + '/market-maker', type: 'external' },
      title: 'TradeMarketMakerTitle',
    },
  ],
};

export const HEADER_NAV_LIST: IHeaderNavItem[] = [
  {
    items: navListData.byCrypto,
    title: 'Buy Crypto',
  },
  {
    link: process.env.BASE_URL + '/markets',
    title: 'Markets',
  },
  {
    items: navListData.trade,
    title: 'Trade',
  },
  {
    items: navListData.payments,
    title: 'Payments',
  },
  {
    items: navListData.earn,
    title: 'EarnTitle',
  },
  {
    link: process.env.BASE_URL + '/staking',

    tag: true,
    // tag: true,
    title: 'StakingTitle',
  },
  {
    link: process.env.BASE_URL + '/blog',
    title: 'Blog',
  },
];

export const WALLET_LINKS: IWalletData = {
  business: [
    {
      icon: <BarChartIcon />,
      link: `${CRYPTOMUS_APP_URL}/business-wallet`,
      title: 'Business Overview',
    },
    {
      icon: <WalletIcon />,
      link: `${CRYPTOMUS_APP_URL}/business-wallet/balances`,
      title: 'Business balances',
    },
    {
      icon: <MerchantIcon />,
      link: `${CRYPTOMUS_APP_URL}/business-wallet/merchants`,
      title: 'Merchants',
    },
    {
      icon: <SendIcon />,
      link: `${CRYPTOMUS_APP_URL}/business-wallet/send`,
      title: 'Send',
    },
    {
      icon: <TransferIcon />,
      link: `${CRYPTOMUS_APP_URL}/business-wallet/transfer`,
      title: 'Transfer',
    },
  ],
  p2p: [
    {
      icon: <BarChartIcon />,
      link: `${CRYPTOMUS_APP_URL}/p2p-wallet`,
      title: 'P2P Overview',
    },
    {
      icon: <WalletIcon />,
      link: `${CRYPTOMUS_APP_URL}/p2p-wallet/balances`,
      title: 'P2P balances',
    },
    {
      icon: <SendIcon />,
      link: `${CRYPTOMUS_APP_URL}/p2p-wallet/send`,
      title: 'Send',
    },
    {
      icon: <TransferIcon />,
      link: `${CRYPTOMUS_APP_URL}/p2p-wallet/transfer`,
      title: 'Transfer',
    },
    {
      divider: true,
      icon: <P2PIcon />,
      link: 'https://p2p.cryptomus.com/',
      title: 'P2P Trade',
    },
  ],
  personal: [
    {
      icon: <BarChartIcon />,
      link: `${CRYPTOMUS_APP_URL}/personal-wallet`,
      title: 'Personal Overview',
    },
    {
      icon: <WalletIcon />,
      link: `${CRYPTOMUS_APP_URL}/personal-wallet/balances`,
      title: 'Personal balances',
    },
    {
      icon: <ReceiveIcon />,
      link: `${CRYPTOMUS_APP_URL}/personal-wallet/get`,
      title: 'Receive',
    },
    {
      icon: <SendIcon />,
      link: `${CRYPTOMUS_APP_URL}/personal-wallet/send`,
      title: 'Send',
    },
    {
      icon: <RefreshIcon />,
      link: `${CRYPTOMUS_APP_URL}/personal-wallet/convert`,
      title: 'Convert',
    },
    {
      icon: <TransferIcon />,
      link: `${CRYPTOMUS_APP_URL}/personal-wallet/transfer`,
      title: 'Transfer',
    },
    {
      icon: <StakingIcon />,
      link: `${CRYPTOMUS_APP_URL}/personal-wallet/staking`,
      title: 'StakingTitle',
    },
  ],
  trading: [
    {
      icon: <BarChartIcon />,
      link: `${CRYPTOMUS_APP_URL}/trade-wallet`,
      title: 'Trading Overview',
    },
    {
      icon: <TradingChartIcon />,
      link: '/exchange/trading',
      title: 'Spot Trading',
    },
    {
      icon: <SendIcon />,
      link: `${CRYPTOMUS_APP_URL}/trade-wallet/send`,
      title: 'Send',
    },
    {
      icon: <TransferIcon />,
      link: `${CRYPTOMUS_APP_URL}/trade-wallet/transfer`,
      title: 'Transfer',
    },
  ],
};

export const linkMenuList: INavListItem[] = [
  {
    description: 'AffiliateDescription',
    link: { href: process.env.BASE_URL + '/affiliate', type: 'external' },
    title: 'AffiliateTitle',
  },
  {
    description: 'EarnDescription',
    link: { href: process.env.BASE_URL + '/earn', type: 'external' },
    title: 'EarnTitle',
  },
];

export const BURGER_MENU_LIST_DATA: IBurgerMenuItem[] = [
  {
    icon: <PromotionsIcon />,
    items: linkMenuList.map(
      (item) =>
        ({
          description: item.description,
          icon: item.icon,
          link: item.link,
          title: item.title,
        }) as INavListItem
    ),
    title: 'CRMS',
  },
  {
    icon: <ProccessingIcon />,
    items: navListData.byCrypto,
    title: 'Buy Crypto',
  },
  {
    icon: <GlobeIcon />,
    link: { href: process.env.BASE_URL + '/markets', type: 'external' },
    title: 'Markets',
  },
  {
    icon: <StakingIcon />,
    items: navListData.trade,
    title: 'Trade',
  },
  {
    icon: <InvoiceIcon />,
    items: navListData.payments,
    title: 'Payments',
  },
  {
    icon: <CardIcon />,
    items: navListData.earn,
    title: 'EarnTitle',
  },
  {
    icon: <WalletIcon />,
    link: { href: process.env.BASE_URL + '/staking', type: 'external' },
    title: 'StakingTitle',
  },
  {
    icon: <BarChartIcon />,
    link: { href: process.env.BASE_URL + '/blog', type: 'external' },
    title: 'Blog',
  },
];

export const userCenterLinks: IItemData[] = [
  {
    icon: <OverviewIcon />,
    link: `${CRYPTOMUS_APP_URL}/overview`,
    title: 'Overview',
  },
  {
    icon: <BlankIcon />,
    link: `${CRYPTOMUS_APP_URL}/history/transactions`,
    title: 'Transactions history',
  },
  {
    icon: <SettingsIcon />,
    link: `${CRYPTOMUS_APP_URL}/settings/preference/basic-preferences`,
    title: 'Settings',
  },
];

export const useCenterSubLinks: IItemData[] = [
  {
    icon: <ReferralsIcon />,
    link: `${CRYPTOMUS_APP_URL}/settings/promo`,
    title: 'Referrals',
  },
  {
    icon: <GiftIcon />,
    link: `${CRYPTOMUS_APP_URL}/awards`,
    title: 'Awards center',
  },
];
