'use client';

import React from 'react';

import { useMediaQuery } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';
import { QRCodeSVG } from 'qrcode.react';

import LogoIcon from 'icons/common/icon-cat.svg';

import styles from './download-app-button.module.scss';

const DownloadAppButton: React.FC = () => {
  const { t } = useTranslation('main');
  const isTablet = useMediaQuery('tablet');

  return (
    <div className={styles.qr_menu}>
      <h4>{t('Download App')}</h4>
      <div className={styles.qr_menu__qr}>
        <QRCodeSVG
          bgColor='transparent'
          fgColor='#000000'
          level='Q'
          size={isTablet ? 122 : 157}
          value='https://apps.apple.com/app/id6464404665'
        />
        <div className={styles.qr__logo}>
          <LogoIcon />
        </div>
      </div>
    </div>
  );
};

export default DownloadAppButton;
