import React from 'react';
import Link from 'next/link';

import clsx from 'clsx';
import { CounterBadge, IconWrapper, Typography } from 'cryptomus-aurora-kit';

import styles from './link-item.module.scss';

interface ILinkItemProps {
  icon: JSX.Element;
  title: string;
  counter?: number;
  link?: string;
  onClick?: () => void;
  rightEl?: JSX.Element;
  subTitle?: string;
  withSub?: boolean;
}

const LinkItem: React.FC<ILinkItemProps> = ({
  title,
  icon,
  link,
  subTitle,
  counter,
  onClick,
  rightEl,
  withSub = false,
}) => {
  const getEl = (
    onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  ) => (
    <button
      className={clsx(styles.item, styles.wrapper)}
      tabIndex={0}
      onClick={(e) => onClick?.(e)}
    >
      <IconWrapper
        appearance='select'
        className={styles.item__icon}
        size='medium'
      >
        {icon}
      </IconWrapper>
      <div className={styles.item__content}>
        <Typography
          as='p'
          className={styles.item__content__title}
          fontWeight={500}
          variant='system_h4'
        >
          {title}
        </Typography>
        {subTitle?.length ? (
          <Typography
            as='span'
            className={styles.item__content__subTitle}
            fontWeight={400}
            variant='system_h5'
          >
            {subTitle}
          </Typography>
        ) : null}
      </div>
      {counter || rightEl ? (
        <div className={clsx(styles.right_el, { [styles.with_sub]: withSub })}>
          {rightEl || <CounterBadge counter={counter ?? 0} />}
        </div>
      ) : null}
    </button>
  );

  if (link)
    return (
      <Link href={link} tabIndex={-1} onClick={() => onClick?.()}>
        {getEl()}
      </Link>
    );

  return getEl((e) => {
    e?.preventDefault();
    onClick?.();
  });
};

export default LinkItem;
