import clsx from 'clsx';
import { IconWrapper, Typography } from 'cryptomus-aurora-kit';

import type { IItemData } from 'components/header/header.types';

import styles from './wallet-item.module.scss';

export const WalletItem: React.FC<IItemData & { onSelect?: () => void }> = ({
  title,
  icon,
  link,
  onSelect,
}) => {
  const getItem = (
    Tag: keyof HTMLElementTagNameMap = 'button',
    link?: string
  ) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Tag
      {...(link ? { href: link } : undefined)}
      className={clsx(styles.wallets_item)}
      tabIndex={0}
      onClick={() => onSelect?.()}
    >
      <IconWrapper appearance='select' size='medium'>
        {icon}
      </IconWrapper>
      <Typography as='p' fontWeight={500} variant='system_h4'>
        {title}
      </Typography>
    </Tag>
  );

  if (link) return getItem('a', link);

  return getItem();
};
