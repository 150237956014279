export const getLanguageFullName = (code: string) => {
  const FULL_NAMES: {
    [key: string]: string;
  } = {
    ar: 'العربية',
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fa: 'فارسی',
    fr: 'Français',
    ja: '日本語',
    kk: 'Қазақша',
    ko: '한국어',
    pa: 'ਪੰਜਾਬੀ',
    pl: 'Polski',
    pt: 'Português',
    ru: 'Русский',
    tr: 'Türkçe',
    uk: 'Українська',
    uz: "O'zbekcha",
    zh: '中文',
  };

  return FULL_NAMES[code];
};
