import Link from 'next/link';

import LogoIcon from 'public/icons/logo/logo.svg';
import LogoText from 'public/icons/logo/logo-text.svg';

import styles from './header-logo.module.scss';

const HeaderLogo = () => (
  <Link
    aria-label='Go to cryptomus.com'
    className={styles.logo__wrapper}
    href='/'
    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
    tabIndex={1}
  >
    <LogoIcon className={styles.logo__icon} />
    <LogoText className={styles.logo__text} />
  </Link>
);

export default HeaderLogo;
