'use client';

import React, { useEffect, useMemo, useState } from 'react';

import NotificationCenterContent from './notification-center-content/notification-center-content';

import { useQuery } from '@tanstack/react-query';
import { DropdownMenu, IconButton, useMediaQuery } from 'cryptomus-aurora-kit';

import { UserService } from 'services/user.service';

import BellIcon from 'public/icons/header/icon-bell.svg';

import { useTranslation } from 'next-i18next';
import BurgerMenuContainer from 'ui/burger-menu-container/burger-menu-container';
import styles from './notification-center.module.scss';

const NotificationCenter: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isTablet = useMediaQuery('tablet');
  const { i18n } = useTranslation();
  const dir = useMemo(
    () => (['ar', 'fa'].includes(i18n.language) ? 'rtl' : 'ltr'),
    [i18n.language]
  );

  const { data: data_getNotificationsUnread } = useQuery({
    queryFn: UserService.getNotificationsUnread,
    queryKey: [UserService.getNotificationsUnread.queryKey],
    refetchInterval: 30_000,
  });

  useEffect(() => {
    setIsOpen(false);
  }, [isTablet]);

  return isTablet ? (
    <>
      <IconButton
        active={isOpen}
        appearance='header'
        className={styles.header_btn}
        counter={data_getNotificationsUnread?.data?.result?.count}
        size='small'
        onClick={() => setIsOpen(!isOpen)}
      >
        <BellIcon />
      </IconButton>
      <BurgerMenuContainer
        align='right'
        direction={dir}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <NotificationCenterContent
          closeMenu={() => setIsOpen(false)}
          unread_notifications={
            data_getNotificationsUnread?.data?.result?.count
          }
        />
      </BurgerMenuContainer>
    </>
  ) : (
    <DropdownMenu
      className={styles.notifications}
      isOpen={isOpen}
      maxHeight={632}
      placement='bottom-end'
      setIsOpen={setIsOpen}
      width={403}
      parent={() => (
        <IconButton
          active={isOpen}
          appearance='header'
          className={styles.header_btn}
          counter={data_getNotificationsUnread?.data?.result?.count}
          size='small'
          onClick={() => setIsOpen(!isOpen)}
        >
          <BellIcon />
        </IconButton>
      )}
    >
      <NotificationCenterContent
        unread_notifications={data_getNotificationsUnread?.data?.result?.count}
      />
    </DropdownMenu>
  );
};

export default NotificationCenter;
