import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import type { ControlItem as ControlItemType } from '../../header.types';
import ControlItem from './control-items/control-item/control-item';
import DownloadAppButton from './control-items/download-app-button/download-app-button';
import NotificationCenter from './control-items/notification-center/notification-center';
import SupportCenter from './control-items/support-center/support-center';
import UnAuthItems from './control-items/user-center/unauth-items/unauth-items';
import UserCenter from './control-items/user-center/user-center';
import Wallets from './control-items/wallets/wallets';

import { useGetUserInfo } from 'services/query-hooks/use-get-user-info';

import DownloadIcon from 'public/icons/header/Download.svg';
import MessageIcon from 'public/icons/header/Support_center.svg';
import WalletsIcon from 'public/icons/header/wallet3.svg';

import styles from './control-list.module.scss';

interface IControlListProps {
  isAuth?: boolean;
}

const ControlList: React.FC<IControlListProps> = ({ isAuth = true }) => {
  const [openEl, setOpenEl] = useState<ControlItemType | null>(null);
  const [mouted, setMouted] = useState<boolean>(false);
  const pathName = usePathname();

  useEffect(() => {
    setMouted(false);
    setTimeout(() => setMouted(true), 0); //ЭТОТ КУСОК ФИКСИТ АКУТАЛИЗАЦИЮ ДРОПДАУНОВ В СЕРВЕРНЫх КОМПОНЕНТАх при смене роута TODO THINK ABOUT IT
  }, [pathName]);

  const setOpenElByType = (type: ControlItemType, value: boolean) => {
    if (value) {
      if (type !== openEl) setOpenEl(type);
    } else type === openEl && setOpenEl(null);
  };

  const isActive = (type: ControlItemType) => type === openEl;

  const { data: userInfo } = useGetUserInfo(isAuth);

  return (
    mouted && (
      <nav className={styles.control_list}>
        {isAuth ? (
          <>
            <div className={styles.user_center}>
              <UserCenter />
            </div>
            <div className={styles.divider} />
            <ControlItem
              hideInMobile
              icon={<WalletsIcon />}
              isOpen={isActive('wallets')}
              placement='bottom-end'
              setIsOpen={(value: boolean) => setOpenElByType('wallets', value)}
              width={260}
              content={(closeMenu, burger) => (
                <Wallets
                  burger={burger}
                  closeMenu={closeMenu}
                  onSelect={() => setOpenElByType('wallets', false)}
                />
              )}
            />
            <div className={styles.notifications}>
              <NotificationCenter />
            </div>

            <ControlItem
              counter={userInfo?.data.result.unread_ticket_messages || 0}
              icon={<MessageIcon />}
              isOpen={isActive('support_center')}
              placement='bottom-end'
              width={334}
              content={(closeMenu, burger) => (
                <SupportCenter
                  burger={burger}
                  closeMenu={closeMenu}
                  unread_tickets={
                    userInfo?.data.result.unread_ticket_messages || 0
                  }
                  onSelect={() => setOpenElByType('support_center', false)}
                />
              )}
              setIsOpen={(value: boolean) =>
                setOpenElByType('support_center', value)
              }
            />
          </>
        ) : (
          <UnAuthItems />
        )}
        <ControlItem
          hideInTablet
          content={() => <DownloadAppButton />}
          icon={<DownloadIcon />}
          isOpen={isActive('download_app_button')}
          placement='bottom-end'
          width={238}
          setIsOpen={(value: boolean) =>
            setOpenElByType('download_app_button', value)
          }
        />
      </nav>
    )
  );
};

export default ControlList;
