import { api } from './api';
import type { IServiceMethod } from './models/global.models';

export interface IBasicResponseData<T> {
  result: T;
  state?: number;
}

interface IResult {
  geo: string;
  isError?: boolean;
}

export interface IPayloadItem {
  payload: string;
  type: string;
  name?: string;
  resend?: boolean;
}

const equivalentCurrency: IServiceMethod<
  IPayloadItem[],
  { currency_code: string }
> = (payload) => {
  const { currency_code } = payload ?? {};

  return api.post('v1/user/settings/equivalent-currency/set', {
    currency_code,
  });
};

equivalentCurrency.queryKey = 'equivalentCurrency';

export const SettingsService = {
  equivalentCurrency,
  async getGeoAxios() {
    const geo =
      await api.get<IBasicResponseData<IResult>>('v1/fingerprint/geo');

    return geo.data;
  },
};
