import { toast } from 'react-toastify';

/**
 * Функция showErrorToasts предназначена для
 * отображения сообщений об ошибках в виде уведомлений
 * (тостов) на основе переданного объекта ответа.
 * Рекурсивно ищет объект errors в ответе и выводит тосты для всех ошибок
 */

export const showErrorToasts = (response: any) => {
  const isObject = response !== null && typeof response === 'object';

  if (!isObject) return;

  const handleErrors = (obj: any) => {
    if (!obj || typeof obj !== 'object') return;

    const hasErrors = Object.prototype.hasOwnProperty.call(obj, 'errors');
    const hasMessage = Object.prototype.hasOwnProperty.call(obj, 'message');

    if (hasErrors) {
      for (const key in obj.errors) {
        const value = obj.errors[key];

        if (Array.isArray(value) && value.length > 0) {
          for (const arrayElement of value) {
            if (typeof arrayElement === 'string') {
              toast.error(arrayElement);
            }
          }
        } else if (
          value !== null &&
          value !== undefined &&
          typeof value === 'string'
        ) {
          toast.error(value);
        }
      }
    } else if (hasMessage && typeof obj.message === 'string') {
      toast.error(obj.message);
    } else {
      for (const key in obj) {
        handleErrors(obj[key]);
      }
    }
  };

  handleErrors(response);
};
