import { useLayoutEffect, useState } from 'react';

type BreakpointsKey = 'm' | 'mL' | 'tS' | 't' | 'tL' | 'l' | 'lC' | 'lL' | 'xL';

type MediaQueryValue = BreakpointsKey | number;

const Breakpoints: Record<BreakpointsKey, number> = {
  l: 1024,
  lC: 1150,
  lL: 1440,
  m: 375,
  mL: 425,
  t: 768,
  tL: 920,
  tS: 576,
  xL: 2560,
};

export const useMediaQuery = (media: MediaQueryValue): boolean => {
  const [isBreakPoint, setIsBreakPoint] = useState<boolean>(false);

  const getBreakPoint = (media: MediaQueryValue): number => {
    if (typeof media === 'number') return media;

    return Breakpoints[media];
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= getBreakPoint(media)) {
        setIsBreakPoint(true);
      } else {
        setIsBreakPoint(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [media]);

  return isBreakPoint;
};
