import React from 'react';

import { Typography } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';
import { QRCodeSVG } from 'qrcode.react';

import LogoIcon from 'icons/common/icon-cat.svg';

import styles from './download-app-button.module.scss';

const DownloadAppButton: React.FC = () => {
  const { t } = useTranslation('main');

  return (
    <div className={styles.qr_menu}>
      <Typography as='p' variant='system_h4'>
        {t('layout.header.Download App')}
      </Typography>
      <div className={styles.qr_menu__qr}>
        <QRCodeSVG
          bgColor='transparent'
          fgColor='var(--primary)'
          level='Q'
          size={157}
          value='https://apps.apple.com/app/id6464404665'
        />
        <div className={styles.qr__logo}>
          <LogoIcon />
        </div>
      </div>
      {/* <Button
        appearance='secondary'
        linkProps={{ href: '#', type: 'internal' }}
        size='s'
        className={clsx(styles.qr_menu__btn, 'customFocus')}
      >
        {t('More options')}
      </Button> */}
      {/* <Typography as='span' variant='system_h5' fontWeight={400}>
        {t('For mobile and desktop')}
      </Typography> */}
    </div>
  );
};

export default DownloadAppButton;
