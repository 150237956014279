import type { ICurrency } from 'services/config.service';
import { UserService } from 'services/user.service';

// TODO some routes
export const INVALIDATE_QUERIES = [
  UserService.getUserInfo.queryKey,
  UserService.getWallets.queryKey,
];

export const defaultCurrency: ICurrency = {
  currency: 'USD',
  icon: null,
  symbol: '$',
  type: 'fiat',
};
