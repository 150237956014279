'use client';

import React, { useEffect, useState } from 'react';

import { defaultCurrency, INVALIDATE_QUERIES } from './constant';
import CurrencyItem from './currency-item/currency-item';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SelectorSearch, useDebounce } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import type { ICurrency, IResponseErrors } from 'services/config.service';
import { ConfigService } from 'services/config.service';
import { useGetUserInfo } from 'services/query-hooks/use-get-user-info';
import { SettingsService } from 'services/setting.service';
import { useGetSelectorHeightInBurger } from 'hooks/use-get-selector-height-in-burger';
import { showErrorToasts } from 'utils/show-error-toasts';

interface IFiatCurrencySelect {
  closeSubMenu?: () => void;
  inBurger?: boolean;
  maxHeight?: string;
  onChange?: (currency: ICurrency) => void;
}

const FiatCurrencySelect: React.FC<IFiatCurrencySelect> = ({
  onChange,
  maxHeight,
  inBurger = false,
  closeSubMenu,
}) => {
  const { t } = useTranslation('main');

  const [selectedCurrency, setSelectedCurrency] =
    useState<ICurrency>(defaultCurrency);

  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 400);

  const selectorHeight = useGetSelectorHeightInBurger();
  const computedMaxHeight = inBurger ? selectorHeight : maxHeight;

  const queryClient = useQueryClient();

  const { data: userInfo } = useGetUserInfo();

  const { data: data_CurrencyList, isLoading } = useQuery({
    queryFn: () =>
      ConfigService.getCurrencyListFiat({ search: debouncedSearchValue }),
    queryKey: [
      ConfigService.getCurrencyListFiat.queryKey,
      debouncedSearchValue,
    ],
  });

  const { mutate: equivalentCurrency } = useMutation({
    mutationFn: (currency: string) =>
      SettingsService.equivalentCurrency({
        currency_code: currency,
      }),
    mutationKey: [
      SettingsService.equivalentCurrency.queryKey,
      selectedCurrency.currency,
    ],
    onError: (error: IResponseErrors) => {
      showErrorToasts(error?.response?.data.message);
    },
    onSuccess: () => {
      onChange?.(selectedCurrency);
      closeSubMenu?.();

      INVALIDATE_QUERIES.forEach((query) => {
        queryClient.refetchQueries({ queryKey: [query] });
      });
    },
  });

  useEffect(() => {
    onChange?.(selectedCurrency);
  }, [selectedCurrency, onChange]);

  useEffect(() => {
    if (
      userInfo?.data?.result.equivalent_currency_code &&
      data_CurrencyList?.data.result?.length
    ) {
      const currency = data_CurrencyList?.data.result?.find(
        (currency) =>
          currency.currency === userInfo?.data?.result.equivalent_currency_code
      );

      if (currency) {
        setSelectedCurrency(currency);
      }
    }
  }, [userInfo?.data, data_CurrencyList]);

  const handleChange = (currency: ICurrency) => {
    setSelectedCurrency(currency);
    equivalentCurrency(currency.currency);
  };

  return (
    <SelectorSearch
      isLoading={isLoading}
      items={data_CurrencyList?.data.result ?? []}
      maxHeight={computedMaxHeight}
      notFoundText={t('layout.header.Nothing found')}
      searchMaxLength={16}
      searchPlaceHolder={t('layout.header.Search')}
      searchValue={searchValue}
      selectedItem={selectedCurrency}
      setSearchValue={setSearchValue}
      renderItem={(item: ICurrency) => (
        <CurrencyItem
          currency={item.currency}
          symbol={item.symbol ?? ''}
          onClick={() => handleChange(item)}
        />
      )}
      translations={{
        notFoundDefault: t('layout.header.Nothing found'),
        searchDefault: t('layout.header.Search'),
      }}
    />
  );
};

export default FiatCurrencySelect;
