import { useSnackbar } from 'notistack';
import type { RefObject } from 'react';

type CopyFn = () => void;

export const useCopyToClipboard = (
  ref: RefObject<HTMLElement>,
  message: string,
  textToCopy?: string
): [CopyFn, boolean] => {
  const { enqueueSnackbar } = useSnackbar();

  const navigator = typeof globalThis !== 'undefined' && globalThis?.navigator;

  const hasClipboardApi = !!navigator?.clipboard?.writeText;

  const copyText: CopyFn = () => {
    if (hasClipboardApi && textToCopy) {
      navigator.clipboard.writeText(textToCopy || '');
      enqueueSnackbar(message, {
        preventDuplicate: true,
        variant: 'success',
      });
    } else if (hasClipboardApi && ref?.current?.textContent !== '') {
      navigator.clipboard.writeText(ref?.current?.textContent || '');
      enqueueSnackbar(message, {
        preventDuplicate: true,
        variant: 'success',
      });
    }
  };

  return [copyText, hasClipboardApi];
};
