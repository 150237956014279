export const LOCALES = [
  'en',
  'es',
  'de',
  'fr',
  'ru',
  'uk',
  'tr',
  'zh',
  'uz',
  'kk',
  'pl',
  'ar',
  'ko',
  'ja',
  'pt',
  'fa',
  'pa',
] as const;

export type TLocale = (typeof LOCALES)[number];
