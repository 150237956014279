import { Skeleton } from 'cryptomus-aurora-kit';

import styles from './notification-skeleton.module.scss';

const NotificationSkeleton = () => (
  <div className={styles.skeletons}>
    {/* <Skeleton isCircular className={styles.skeletons__icon} /> */}
    <div className={styles.skeletons__rows}>
      <Skeleton className={styles.rows__date} />
      <Skeleton className={styles.rows__item} />
      <Skeleton className={styles.rows__item} />
      <Skeleton className={styles.rows__item} />
      <Skeleton className={styles.rows__item} />
      <Skeleton className={styles.rows__item} />
      <Skeleton className={styles.rows__item} />
      <Skeleton className={styles.rows__item} />
    </div>
  </div>
);

export default NotificationSkeleton;
