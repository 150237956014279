import React from 'react';

import { joinCryptomusData } from '../main-page.data';

import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import styles from './join-cryptomus-block.module.scss';

interface IJoinCryptomusBlockPropsTypes {
  locale: string;
}

const JoinCryptomusBlock: React.FC<IJoinCryptomusBlockPropsTypes> = ({
  locale,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2>{t('Join Cryptomus Community')}</h2>

      <div className={styles.container__links}>
        {joinCryptomusData?.map((item) => (
          <a
            key={item.link}
            target='_blank'
            href={
              item?.withLocale ? getLocaleLink(item.link, locale) : item.link
            }
          >
            {item.image}
          </a>
        ))}
      </div>
    </div>
  );
};

export default JoinCryptomusBlock;
