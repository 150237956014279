import React, { useMemo, useState } from 'react';

import BurgerHeader from '../../../../burger-menu/burger-header/burger-header';
import { WALLET_LINKS } from '../../../../header.data';
import { WalletItem } from './wallet-item/wallet-item';

import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import type {
  ISubItem,
  mainMenuItem,
} from 'components/header/navigation/nav-list/nav-item/nav-item.types';
import DropdownList from 'ui/dropdown-list/dropdown-list';

import BusinessIcon from 'public/icons/header/briefcase2.svg';
import TradeIcon from 'public/icons/header/candles.svg';
import P2PIcon from 'public/icons/header/P2PTrade.svg';
import PersonalIcon from 'public/icons/header/wallet3.svg';

import styles from './wallets.module.scss';

export interface IWalletsProps {
  closeMenu: () => void;
  burger?: boolean;
  onSelect?: () => void;
}

const Wallets: React.FC<IWalletsProps> = ({
  onSelect,
  closeMenu,
  burger = false,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('main');
  const { t: tSub } = useTranslation('main:layout.subheader');

  const dir = useMemo(
    () => (['ar', 'fa'].includes(i18n.language) ? 'rtl' : 'ltr'),
    [i18n.language]
  );

  const [openSubMenu, setOpenSubmenu] = useState<ISubItem | null>(null);

  const handleSelect = () => {
    onSelect?.();
    closeMenu?.();
  };

  const LinksWrapper: React.FC<{
    type: 'personal' | 'business' | 'trading' | 'p2p';
  }> = ({ type }) => (
    <div className={clsx(styles.wallets_links, { [styles.burger]: burger })}>
      {WALLET_LINKS?.[type].map((item) => (
        <WalletItem
          key={item.title}
          icon={item.icon}
          link={item.link}
          title={t('layout.header.' + item.title)}
          onSelect={handleSelect}
        />
      ))}
    </div>
  );

  const walletsMenuMain: mainMenuItem[] = [
    {
      el: (
        <WalletItem
          icon={<PersonalIcon />}
          title={t('layout.header.Personal')}
        />
      ),
      subItem: {
        el: <LinksWrapper type='personal' />,
        title: t('layout.header.Personal'),
      },
    },
    {
      el: (
        <WalletItem
          icon={<BusinessIcon />}
          title={t('layout.header.Business')}
        />
      ),
      subItem: {
        el: <LinksWrapper type='business' />,
        title: t('layout.header.Business'),
      },
    },
    {
      el: <WalletItem icon={<P2PIcon />} title='P2P' />,
      subItem: {
        el: <LinksWrapper type='p2p' />,
        title: 'P2P',
      },
    },
    {
      el: <WalletItem icon={<TradeIcon />} title={tSub('Trading')} />,
      subItem: {
        el: <LinksWrapper type='trading' />,
        title: tSub('Trading'),
      },
    },
  ];

  const walletsMenu: mainMenuItem[] = useMemo(
    () =>
      burger
        ? [
            { el: <BurgerHeader reversed closeBurger={closeMenu} /> },
            ...walletsMenuMain,
          ]
        : walletsMenuMain,
    [walletsMenuMain, burger]
  );

  return (
    <DropdownList
      burger={burger}
      direction={dir}
      mainLinks={walletsMenu}
      openSubMenuList={openSubMenu}
      setOpenSubMenuList={setOpenSubmenu}
    />
  );
};

export default Wallets;
