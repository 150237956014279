import type { IServiceMethod } from './models/global.models';
import type { IUseAuthData } from './query-hooks/use-auth';

import { api } from 'services/api';
import type { IBasicResponseData } from 'services/setting.service';

type Spot = 'explorer' | 'blog' | 'payform';

export interface IStakingCurrency {
  first_rewards_in_hours: string;
  uuid: string;
  apr?: string;
  currency?: {
    code: string;
    decimal: number;
    icon_url: string;
    name: string;
  };
  max?: {
    amount: string;
    amount_equivalent: string;
    equivalent_currency_code: string;
  };
  min?: {
    amount: string;
    amount_equivalent: string;
    equivalent_currency_code: string;
  };
}

export interface IStakingCurrenciesData {
  data: {
    result: IStakingCurrency[];
  };
}

export interface IBanner {
  body: string;
  button_name: string;
  cover_mobile: string;
  cover_tablet: string;
  cover_web: string;
  locale: string;
  redirect_url: string;
  spots: Array<Spot>;
  title: string;
  uuid: string;
}

export interface IMarketsListItem {
  BaseCurrencySum: number;
  LastPrice: number;
  Max24h: number;
  Min24h: number;
  Percent24h: number;
  QuoteCurrencySum: number;
  Symbol: string;
}

export interface ICurrencyListItem {
  commission_fee_amount: string;
  currency_code: string;
  currency_icon: string;
  min_amount: string;
  network_code: string;
  post_link: string | null;
}

export interface IUserInfo {
  default_language: string;
  email: string | null;
  equivalent_currency_code: string;
  equivalent_currency_symbol: string;
  has_password: boolean;
  is_allowed_autopayout: boolean;
  is_allowed_excel_payout: boolean;
  is_fiat_receivable: string | null;
  is_kyc_verified: boolean;
  lock_payout_to: string | null;
  phone: string | null;
  telegram: boolean;
  timezone: string;
  unread_ticket_messages: number;
  uuid: string;
  two_factor?: {
    email: boolean;
    google2fa: boolean;
    phone: boolean;
  };
}

export interface IGetNotificationUnread {
  count: number;
}

export interface INotificationsItem {
  created_at: string;
  read: boolean;
  text: string;
  title: string;
  type: 'warning' | 'news';
  uuid: string;
  icon?: string;
  link?: string;
  link_text?: string; //Пока предварительно полагаю
}

export interface IWallet {
  currency: string;
  rate_eq: string;
  uuid: string;
  balance?: string;
  balance_equivalent?: string;
  currency_decimal?: number;
  currency_hex?: string;
  currency_icon?: string;
  currency_name?: string;
  equivalent_currency_code?: string;
  equivalent_currency_symbol?: string;
  hold?: string;
  hold_equivalent?: string;
  id?: string;
  is_manual_convert_available?: boolean;
  is_payment_available?: boolean;
  is_receivable?: boolean;
  is_stake_available?: boolean;
  is_transferable?: boolean;
  is_withdrawable?: boolean;
  latest_hold_tx_uuid?: number;
  percentage_allocation?: string;
}

export interface IGetWalletResponse {
  items: IWallet[];
  paginate: {
    count: number;
    hasPages: boolean;
    nextCursor: null | string;
    perPage: number;
    previousCursor: null | string;
    total: number;
    allPages?: number;
  };
}

export type IGetWalletSortType =
  | 'name_smallest'
  | 'name_largest'
  | 'balance_smallest'
  | 'balance_largest'
  | 'default';

export enum WalletRequestParamEnum {
  MERCHANT = 'merchant',
  USER = 'user',
  P2P = 'p2p',
  TRADING = 'trade',
}

export type KYCStatus = 'not_passed' | 'in_progress' | 'rejected' | 'approved';

export interface ITwoFactorBind {
  email: boolean;
  google2fa: boolean;
  phone: boolean;
}

export interface IAuthBind {
  apple: boolean;
  facebook: boolean;
  google: boolean;
  telegram: boolean;
  tonkeeper: boolean;
  trezor: boolean;
}

export interface IGetUserSettings {
  api_payout_key: string;
  awards: {
    available_tasks: number;
  };
  binded_auth: IAuthBind;
  country: string | null;
  is_allowed_autopayout: boolean;
  is_pincode_enabled: boolean;
  kyc_status: KYCStatus;
  notification_language: string;
  referral_code: string;
  referral_link: string;
  referral_p2p_trading_percent: number;
  referral_percent: number;
  referral_staking_percent: number;
  two_factor: ITwoFactorBind;
  whitelist_address_enable: boolean;
  whitelist_ip_enable: boolean;
}

const getIsAuth: IServiceMethod<IUseAuthData> = (data: { token: string }) =>
  api.post('v1/login/verify-token', {
    data,
  });

getIsAuth.queryKey = 'isAuth';

const getUserInfo: IServiceMethod<IUserInfo, undefined> = () =>
  api.post('v3/user/info');

getUserInfo.queryKey = 'userInfo';

const getNotificationsUnread: IServiceMethod<IGetNotificationUnread> = () =>
  api.post('v1/user/notifications/list/unread');

getNotificationsUnread.queryKey = 'getNotificationsUnread';

const readAllNotifications: IServiceMethod<void> = () =>
  api.post('v1/user/notifications/read-all');

readAllNotifications.queryKey = 'readAllNotifications';

const getNotifications: IServiceMethod<
  INotificationsItem[],
  { cursor: number }
> = (data) => api.post('v1/user/notifications/list', data);

getNotifications.queryKey = 'getNotifications';

const getWallets: IServiceMethod<IGetWalletResponse> = (params: {
  data: {
    cursor: number;
    is_receivable?: 1;
    per_page?: number;
    search?: string;
    sort_type?: IGetWalletSortType;
  };
  param: WalletRequestParamEnum;
}) =>
  api.post(`v2/${params.param}/wallets`, {
    data: { ...params.data },
  });

getWallets.queryKey = 'wallets';

const getUserSettings: IServiceMethod<IGetUserSettings> = () =>
  api.get('v2/user/settings');

getUserSettings.queryKey = 'getUserSettings';

export const UserService = {
  async getBannersContent(spot: Spot) {
    return api.post<IBasicResponseData<IBanner[]>>('/v1/marketing/banners', {
      spot,
    });
  },
  async getBannersContentFetch(
    spot: Spot,
    locale: string
  ): Promise<IBasicResponseData<IBanner[]>> {
    const url = 'https://api-app.cryptomus.com/v1/marketing/banners';

    const data = {
      spot,
    };

    try {
      const response = await fetch(url, {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Language: locale,
        },
        method: 'POST',
      });

      return response.json();
    } catch (error) {
      throw new Error(`Error fetching data: ${error.message}`);
    }
  },
  getIsAuth,
  getNotifications,
  getNotificationsUnread,
  async getStakingCurrencies() {
    return api.post<IBasicResponseData<IStakingCurrenciesData>>(
      'v1/staking/currencies'
    );
  },
  getUserInfo,
  getUserSettings,
  getWallets,
  readAllNotifications,
};
