import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';
import { IconWrapper, Typography } from 'cryptomus-aurora-kit';

import type { INavListItem } from 'components/header/header.types';

import { getLocaleLink } from 'utils/get-locale-link';

import styles from './nav-el.module.scss';

interface INavElProps {
  hasChildren?: boolean;
  onClick?: () => void;
}

export const NavEl: React.FC<INavListItem & INavElProps> = ({
  title,
  description,
  icon,
  onClick,
  link,
  hasChildren,
}) => {
  const { locale } = useRouter();

  const getNavEl = () => (
    <>
      {icon && (
        <IconWrapper
          appearance='select'
          className={styles.item__icon}
          size='medium'
        >
          {icon}
        </IconWrapper>
      )}
      <div className={styles.content}>
        <Typography as='p' fontWeight={500} variant='system_h4'>
          {title}
        </Typography>
        <Typography
          as='span'
          className={styles.content__description}
          fontWeight={400}
          variant='system_h5'
        >
          {description}
        </Typography>
      </div>
    </>
  );

  if (link?.type === 'internal')
    return (
      <Link
        className={styles.nav__item}
        href={link.href}
        tabIndex={0}
        onClick={() => onClick?.()}
      >
        {getNavEl()}
      </Link>
    );

  if (link?.type === 'external')
    return (
      <a
        className={styles.nav__item}
        href={getLocaleLink(link.href, locale)}
        tabIndex={0}
        onClick={() => onClick?.()}
      >
        {getNavEl()}
      </a>
    );

  return (
    <button
      tabIndex={0}
      className={clsx(styles.nav__item, {
        [styles.with_children]: hasChildren,
      })}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      {getNavEl()}
    </button>
  );
};
