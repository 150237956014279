'use client';

import React from 'react';
import Image from 'next/image';

import { Typography } from 'cryptomus-aurora-kit';

import styles from './language-item.module.scss';

interface ILanguageItemProps {
  icon: string;
  onChange: () => void;
  title: string;
}

const LanguageItem: React.FC<ILanguageItemProps> = ({
  title,
  icon,
  onChange,
}) => (
  <button className={styles.lang_item} onClick={onChange}>
    <Image alt={`${title} flag`} height={24} src={icon} width={24} />
    <Typography as='p' fontWeight={400} variant='system_h4'>
      {title}
    </Typography>
  </button>
);

export default LanguageItem;
