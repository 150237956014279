import { Button } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import styles from './get-started-button.module.scss';

const GetStartedButton = () => {
  const { t } = useTranslation('main');

  return (
    <Button
      appearance='primary'
      as='a'
      className={styles.get_started_button}
      href={`${process.env.CRYPTOMUS_APP_URL}/signup`}
      size='s'
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex={1}
      type='internal'
    >
      {t('layout.header.Get started')}
    </Button>
  );
};

export default GetStartedButton;
