import React from 'react';

import UserAvatar from 'ui/user-avatar/user-avatar';

import styles from './user-avatar-button.module.scss';

interface IUserAvatarButtonProps {
  onClick: () => void;
  size: 'small' | 'medium' | 'big';
  image?: string;
  login?: string;
}

const UserAvatarButton: React.FC<IUserAvatarButtonProps> = ({
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  image,
  size,
  onClick,
  login,
}) => (
  <button className={styles.avatar_wrapper} onClick={onClick}>
    <UserAvatar
      // src={image}
      alt='user avatar'
      loading='lazy'
      login={login}
      quality={100}
      size={size}
    />
  </button>
);

export default UserAvatarButton;
