import clsx from 'clsx';

import AppStoreBtn from 'ui/app-store-btn/app-store-btn';
import DownloadApkBtn from 'ui/download-apk-btn/download-apk-btn';
import GooglePlayBtn from 'ui/google-play-btn/google-play-btn';
import QrDownloadBtn from 'ui/qr-download-btn/qr-download-btn';

import styles from './download-buttons.module.scss';

interface IDownloadButtons {
  apkClassName?: string;
  apkHeight?: number;
  apkWidth?: number;
  appClassName?: string;
  appHeight?: number;
  appWidth?: number;
  className?: string;
  googleClassName?: string;
  googleHeight?: number;
  googleWidth?: number;
  isQr?: boolean;
}

const DownloadButtons: React.FC<IDownloadButtons> = ({
  isQr,
  className,
  appHeight,
  appWidth,
  appClassName,
  googleHeight,
  googleWidth,
  googleClassName,
  apkHeight,
  apkWidth,
  apkClassName,
}) => (
  <div className={clsx(styles.container, className)}>
    <AppStoreBtn
      className={appClassName}
      height={appHeight}
      theme='light'
      width={appWidth}
    />
    <GooglePlayBtn
      className={googleClassName}
      height={googleHeight}
      theme='light'
      width={googleWidth}
    />
    <DownloadApkBtn
      className={apkClassName}
      height={apkHeight}
      width={apkWidth}
    />
    {isQr ?? <QrDownloadBtn />}
  </div>
);

export default DownloadButtons;
