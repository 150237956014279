import { useQuery } from '@tanstack/react-query';

import { UserService } from 'services/user.service';

export const useGetUserInfo = (isAuth: boolean | undefined = true) =>
  useQuery({
    enabled: !!isAuth,
    queryFn: () => UserService.getUserInfo(),
    queryKey: [UserService.getUserInfo.queryKey],
    refetchInterval: 30_000,
  });
