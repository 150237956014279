'use client';
import Image from 'next/image';

import { MAIN_PAGE_STATISTICS } from '../main-page.data';

import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import DownloadButtons from 'ui/download-buttons/download-buttons';

import styles from './sign-up.module.scss';

interface ISignUpPropsTypes {
  locale: string;
}

const SignUp: React.FC<ISignUpPropsTypes> = ({ locale }) => {
  const { t } = useTranslation('main');

  const resultURL = getLocaleLink('https://app.cryptomus.com/overview', locale);

  return (
    <div className={styles.wrapper}>
      <div className={styles.preview}>
        <div className={styles.preview__left_block}>
          <h2>{t('One Account, Endless Possibilities')}</h2>
          <a
            className={styles.preview__left_block__sign_up}
            href={resultURL}
            rel='noreferrer'
            target='_blank'
          >
            {t('Sign Up')}
          </a>
          <div className={styles.preview__left_block__links}>
            <DownloadButtons googleHeight={40} googleWidth={137} />
          </div>
        </div>
        <div className={styles.preview__icon}>
          <Image
            alt='sign-up-right-block-image'
            height={402}
            src='/img/main/sign-up-right-block-image.png'
            width={644}
          />
        </div>
      </div>

      <div className={styles.statistic}>
        {MAIN_PAGE_STATISTICS?.map((item) => (
          <div key={item.label} className={styles.statistic__item}>
            <h3>{item.count}</h3>
            <span>{t(item.label)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignUp;
