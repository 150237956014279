// import GooglePlayLogo from 'icons/main/google-play-logo.svg';
import Image from 'next/image';

import type { TTradingBadgePropsTypes } from 'components/main-page/our-products/our-products-item/trading-badge/trading-badge';

import CryptomusAccountIcon from 'icons/main/cryptomus-account-icon.svg';
import BuyCryptoIcon from 'icons/main/products/buy-crypto-icon.svg';
import ConvertIcon from 'icons/main/products/convert-icon.svg';
import CryptoProcessingIcon from 'icons/main/products/crypto-processing-icon.svg';
import DirectoryIcon from 'icons/main/products/directory-icon.svg';
import ExplorerIcon from 'icons/main/products/explorer-icon.svg';
import WhiteLabelIcon from 'icons/main/products/label-icon.svg';
import MassPaymentsIcon from 'icons/main/products/mass-payments-icon.svg';
import P2PExchangeIcon from 'icons/main/products/p2p-icon.svg';
import StakingIcon from 'icons/main/products/staking-icon.svg';
import WalletIcon from 'icons/main/products/wallet-icon.svg';
import TelegramIcon from 'icons/main/telegram-icon.svg';
import XComIcon from 'icons/main/x-com-icon.svg';

export const MAIN_PAGE_STATISTICS = [
  {
    count: '200M',
    label: 'Transactions',
  },
  {
    count: '18',
    label: 'Cryptocurrencies',
  },
  {
    count: '350K+',
    label: 'Users',
  },
  {
    count: '200+',
    label: 'Countries',
  },
];

export interface IOurProduct {
  description: string;
  link: string;
  row: number;
  title: string;
  customBadgeText?: string;
  icon?: JSX.Element;
  link_type?: 'internal' | 'external';
  mobileBackground?: string;
  mobileBackgroundHeight?: string;
  mobileBackgroundWidth?: string;
  mobile_icon?: JSX.Element;
  status?: TTradingBadgePropsTypes;
  type?: string;
}

export const OUR_PRODUCTS: IOurProduct[] = [
  {
    description: 'Simple and convenient crypto trading hub',
    icon: (
      <Image
        alt='render-products-article-spot-trading'
        height={152}
        src='/img/main/render-products-article-spot-trading.png'
        width={244}
      />
    ),
    link: '/exchange/trading',
    mobileBackground: '/img/main/spot-trading-mobile-background.png',
    mobileBackgroundHeight: '122',
    mobileBackgroundWidth: '197',
    row: 1,
    status: 'NEW',
    title: 'Spot trading',
  },
  {
    description:
      'Use our advanced tools to accept crypto payments for your business',
    icon: (
      <Image
        alt='Payment Gateway'
        height={148}
        src='/img/main/render-products-article-wallet.png'
        width={269}
      />
    ),
    link: '/gateway',
    mobileBackground: '/img/main/payment-gateway-mobile-background.png',
    mobileBackgroundHeight: '148',
    mobileBackgroundWidth: '269',
    row: 1,
    title: 'Payment Gateway',
  },

  {
    description:
      'Store and swap funds in a secure wallet with multi-level security',
    link: '/wallet',
    mobile_icon: <WalletIcon />,
    row: 2,
    title: 'Wallet',
  },
  {
    description:
      'Integrate Cryptomus into your project and start getting paid in crypto',
    link: '/processing',
    mobile_icon: <CryptoProcessingIcon />,
    row: 2,
    title: 'Crypto processing',
    type: 'Business',
  },
  // TODO
  // {
  //     row: 2,
  //     title: 'Market Cap',
  //     description: 'Check the precise exchange rate for each coin listed',
  //     link: '/',
  //     type: 'Business',
  //     status: 'Other',
  //     mobile_icon: <MarketCapIcon/>
  // },

  // TODO
  // {
  //     row: 3,
  //     title: 'Widgets',
  //     description: 'Integrate cryptocurrency payments seamlessly',
  //     link: '/',
  //     type: 'Business',
  //     mobile_icon: <WidgetsIcon/>
  // },
  {
    description: 'Bulk payments to your customers with an API',
    link: '/mass-payout',
    mobile_icon: <MassPaymentsIcon />,
    row: 3,
    title: 'Mass Payments',
    type: 'Business',
  },
  {
    description: 'Swap cryptocurrencies instantly for other assets',
    link: '/convert',
    mobile_icon: <ConvertIcon />,
    row: 3,
    title: 'Convert',
  },
  {
    customBadgeText: '20%',
    description: 'Earn up to 20% APR on passive income',
    link: '/staking',
    mobile_icon: <StakingIcon />,
    row: 3,
    status: 'ERROR',
    title: 'Staking',
  },

  {
    description: 'Trade crypto directly with other users',
    link: '/p2p-exchange',
    mobile_icon: <P2PExchangeIcon />,
    row: 4,
    title: 'P2P Exchange',
  },
  {
    description: 'Access crypto  worldwide and pay with any payment method',
    link: '/buy',
    mobile_icon: <BuyCryptoIcon />,
    row: 4,
    title: 'Buy Crypto',
  },
  {
    description: 'The easiest way to track your transactions',
    link: '/explorer',
    mobile_icon: <ExplorerIcon />,
    row: 4,
    status: 'Other',
    title: 'Explorer',
  },
  {
    description: 'Place your business here and attract more customers',
    link: 'https://directory.cryptomus.com/',
    link_type: 'external',
    mobile_icon: <DirectoryIcon />,
    row: 4,
    status: 'Other',
    title: 'Directory',
  },
  {
    description: 'Integrate cryptocurrency payments seamlessly',
    link: '/white_label',
    mobile_icon: <WhiteLabelIcon />,
    row: 4,
    title: 'White Label',
    type: 'Business',
  },
];

export interface IPartnershipDataItem {
  description: string;
  hover_icon: string;
  icon: string;
  link: {
    href: string;
    type: string;
  };
  title: string;
}

export const PARTNERSHIP_DATA: IPartnershipDataItem[] = [
  {
    description:
      'Invite your friends to Cryptomus and earn up to 50% of the fees they pay',
    hover_icon: '/icons/main/render_partnership_article_referal_hover 1.png',
    icon: '/icons/main/render-partnership-article-referal-default-1.svg',
    link: {
      href: 'https://app.cryptomus.com/settings/referral',
      type: 'external',
    },
    title: 'Referral',
  },
  {
    description:
      'Refer your audience to Cryptomus and get a share of our fees and other bonuses',
    hover_icon: '/icons/main/render_partnership_article_affiliate_hover 1.png',
    icon: '/icons/main/render-partnership-article-affiliate-default-1.svg',
    link: {
      href: '/affiliate',
      type: 'internal',
    },
    title: 'Affiliate',
  },
  {
    description:
      'Have your users trade on Cryptomus to get access to maximum profits and special bonuses',
    hover_icon: '/icons/main/render_partnership_article_broker_hover 1.png',
    icon: '/icons/main/render-partnership-article-broker-default-1.svg',
    link: {
      href: '/broker',
      type: 'internal',
    },
    title: 'Broker',
  },
  {
    description: 'Offer the best trading conditions to traders worldwide',
    hover_icon:
      '/icons/main/render_partnership_article_market_maker_hover 1.png',
    icon: '/img/main/render-partnership-article-market-maker-default-1.png',
    link: {
      href: '/market-maker',
      type: 'internal',
    },
    title: 'Market maker',
  },
];

export const joinCryptomusData = [
  {
    image: <XComIcon />,
    link: 'https://x.com/cryptomus',
    withLocale: false,
  },
  {
    image: <TelegramIcon />,
    link: 'https://t.me/cryptomus',
    withLocale: false,
  },
  {
    image: <CryptomusAccountIcon />,
    link: 'https://app.cryptomus.com/',
  },
];
