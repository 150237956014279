import { useState } from 'react';
import Link from 'next/link';

import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import type { IPartnershipDataItem } from 'components/main-page/main-page.data';

import { useMediaQuery } from 'hooks/use-media-query';

import styles from './partnership-swiper-item.module.scss';

interface IPartnershipSwiperItemPropsTypes {
  slide: IPartnershipDataItem;
}

const PartnershipSwiperItem: React.FC<IPartnershipSwiperItemPropsTypes> = ({
  slide,
}) => {
  const { i18n, t } = useTranslation('main');

  const [icon, setIcon] = useState<string>(slide.icon);
  const isTablet = useMediaQuery('t');

  return (
    <div
      className={styles.slide}
      onMouseEnter={() => {
        if (isTablet) return;
        setIcon(slide.hover_icon);
      }}
      onMouseLeave={() => {
        if (isTablet) return;
        setIcon(slide.icon);
      }}
    >
      <img alt='slide' className={styles.slide__icon} src={icon as string} />
      <div className={styles.slide__text}>
        <h5>{t(slide.title)}</h5>
        <span>{t(slide.description ?? '')}</span>
      </div>
      <Link
        type={slide.link.type || 'internal'}
        href={
          slide.link.type === 'external'
            ? getLocaleLink(slide.link.href, i18n.language)
            : slide.link.href
        }
      >
        {t('Go')}
      </Link>
    </div>
  );
};

export default PartnershipSwiperItem;
