import Image from 'next/image';

import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import styles from './download-apk-btn.module.scss';

interface IDownloadApkBtn {
  className?: string;
  height?: number;
  width?: number;
}

const acceptLocales = new Set([
  'de',
  'en',
  'es',
  'uk',
  'ru',
  'tr',
  'zh',
  'uz',
  'kk',
  'pl',
  'ar',
  'ko',
  'ja',
  'pt',
  'fa',
]);

const DownloadApkBtn: React.FC<IDownloadApkBtn> = ({
  className,
  height = 0,
  width = 0,
}) => {
  const { i18n } = useTranslation('common');
  const locale = i18n.language;
  const curLocale = acceptLocales.has(locale) ? locale : 'en';

  return (
    <a
      className={clsx(styles.link, className)}
      href='https://storage.cryptomus.com/apps/crms_v2.0.0.apk'
      rel='noreferrer'
      target='_blank'
    >
      <Image
        alt='Download apk'
        className={styles.image}
        height={height}
        loading='lazy'
        src={`/icons/download-apk-btn/download-apk-btn-${curLocale}.svg`}
        width={width}
      />
    </a>
  );
};

export default DownloadApkBtn;
