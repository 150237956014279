import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import AccessBlock from 'components/main-page/access-block/access-block';
import JoinCryptomusBlock from 'components/main-page/join-cryptomus-block/join-cryptomus-block';
import MediaBlock from 'components/main-page/media-block/media-block';
import OurProducts from 'components/main-page/our-products/our-products';
import PartnershipProgrammes from 'components/main-page/partnership-programmes/partnership-programmes';
import SignUp from 'components/main-page/sign-up/sign-up';
import TradingBanner from 'components/main-page/trading-banner/trading-banner';
import SEOHead from 'ui/seo-head/seo-head';

import styles from './main-page-index.module.scss';

interface IMainPageIndexPropsTypes {
  locale: string;
}

const MainPageIndex: React.FC<IMainPageIndexPropsTypes> = ({ locale }) => {
  const { t } = useTranslation('common');

  return (
    <main className={styles.main}>
      <section className={clsx(styles.container, styles.sign_up__background)}>
        <SEOHead
          description={t('main_meta_description')}
          image='https://storage.cryptomus.com/commercial/index.png'
          noIndex={false}
          title={t('main_meta_title')}
        />
        <SignUp locale={locale} />
      </section>

      <section className={styles.container}>
        <TradingBanner locale={locale} />
      </section>

      <section className={styles.container}>
        <OurProducts locale={locale} />
      </section>

      <section className={styles.container}>
        <MediaBlock />
      </section>

      <section className={styles.partners}>
        <div className={styles.container}>
          <PartnershipProgrammes />
        </div>
      </section>

      <section className={styles.access_block}>
        <div className={styles.container}>
          <AccessBlock />
        </div>
      </section>

      <section className={styles.join_cryptomus_block}>
        <div className={styles.container}>
          <JoinCryptomusBlock locale={locale} />
        </div>
      </section>
    </main>
  );
};

export default MainPageIndex;
