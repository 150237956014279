import React from 'react';

import clsx from 'clsx';
import { CounterBadge, Typography } from 'cryptomus-aurora-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';

import ReadAllIcon from 'public/icons/header/Check-Circle.svg';
import CloseIcon from 'public/icons/header/Close.svg';

import styles from './notification-center-header.module.scss';

interface INotificationCenterHeaderProps {
  // isSelectAll?: boolean;
  isLoading: boolean;
  // selectAll?: () => void;
  cancel?: () => void;
  closeMenu?: () => void;
  nothingFound?: boolean;
  readAll?: () => void;
  selectedCount?: number;
  unreadCount?: number;
}

const variants = {
  hidden: { opacity: 0, transition: { duration: 0.1, type: 'tween' } },
  visible: { opacity: 1, transition: { duration: 0.1, type: 'tween' } },
};

const motionProps = {
  animate: 'visible',
  exit: 'hidden',
  initial: 'hidden',
  variants,
};

const NotificationCenterHeader: React.FC<INotificationCenterHeaderProps> = ({
  // selectAll,
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  cancel,
  readAll,
  closeMenu,
  nothingFound,
  selectedCount = 0,
  unreadCount = 0,
  // isSelectAll = false,
  isLoading = false,
}) => {
  const { t } = useTranslation('main');

  const getDefaultTitle = () => {
    if (selectedCount) return null;

    return (
      <motion.div {...motionProps} key='default'>
        <Typography as='p' variant='button'>
          {t('layout.header.Notifications')}
        </Typography>
      </motion.div>
    );
  };

  // const getSelectAllBtn = () => {
  //   if (!selectedCount || isSelectAll) return null;
  //   return (
  //     <motion.button
  //       {...motionProps}
  //       key='select all'
  //       className={clsx(styles.header__btn, 'customFocus')}
  //       // onClick={selectAll}
  //     >
  //       <Typography as='span' variant='button'>
  //         {t('Select all')}
  //       </Typography>
  //     </motion.button>
  //   );
  // };

  // const getCancelBtn = () => {
  //   if (!isSelectAll) return null;
  //   return (
  //     <motion.button
  //       {...motionProps}
  //       key='cancel'
  //       className={clsx(styles.header__btn, 'customFocus')}
  //       onClick={cancel}
  //     >
  //       <Typography as='span' variant='button'>
  //         {t('Cancel')}
  //       </Typography>
  //     </motion.button>
  //   );
  // };

  const getUnreadCounter = () => {
    if (!unreadCount) return null;

    return (
      <motion.div {...motionProps} key='unread count'>
        <CounterBadge counter={unreadCount} />
      </motion.div>
    );
  };

  // const getSelectedCounter = () => {
  //   if (!selectedCount) return null;
  //   return (
  //     <motion.div {...motionProps} key='select count' className={styles.count}>
  //       <Typography as='span' variant='button'>
  //         ·
  //       </Typography>
  //       <Typography as='span' variant='button'>
  //         {selectedCount}
  //       </Typography>
  //     </motion.div>
  //   );
  // };

  const getReadAllBtn = () => {
    if (selectedCount || !unreadCount || nothingFound) return null;

    return (
      <motion.button
        {...motionProps}
        key='read all'
        className={clsx(styles.header__readAll_btn, 'customFocus')}
        disabled={unreadCount === 0 || isLoading}
        onClick={readAll}
      >
        <ReadAllIcon />
        <Typography
          as='span'
          className={styles.readAll_btn__text}
          fontWeight={500}
          variant='button'
        >
          {t('layout.header.Read all')}
        </Typography>
      </motion.button>
    );
  };

  const getCloseBtn = () => {
    if (!closeMenu) return null;

    return (
      <button
        className={clsx(styles.header__close_btn, 'customFocus')}
        onClick={closeMenu}
      >
        <CloseIcon />
      </button>
    );
  };

  return (
    <div className={styles.header}>
      <AnimatePresence mode='wait'>
        {getDefaultTitle()}
        {/* {getSelectAllBtn()}
        {getCancelBtn()} */}
        {/* {getSelectedCounter()} */}
        {getUnreadCounter()}
        <div className={styles.header__actions}>
          {getReadAllBtn()}
          {getCloseBtn()}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default NotificationCenterHeader;
