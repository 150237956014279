import Link from 'next/link';

import type { ISupportItem } from '../support-center';

import { CounterBadge, IconWrapper, Typography } from 'cryptomus-aurora-kit';

import styles from './support-item.module.scss';

export const SupportItem: React.FC<ISupportItem> = ({
  title,
  description,
  icon,
  link,
  onSelect,
  counter,
  type = 'internal',
}) => (
  <Link
    className={styles.support__item}
    href={link}
    onClick={onSelect}
    {...(type === 'external'
      ? {
          rel: 'noopener noreferrer',
          target: '_blank',
        }
      : undefined)}
  >
    <div className={styles.item__container}>
      <IconWrapper
        appearance='select'
        className={styles.item__icon}
        size='medium'
      >
        {icon}
      </IconWrapper>
      <div className={styles.item__content}>
        <div className={styles.content__title}>
          <Typography as='p' fontWeight={500} variant='system_h4'>
            {title}
          </Typography>
        </div>
        <Typography
          as='span'
          className={styles.content__description}
          fontWeight={400}
          variant='system_h5'
        >
          {description}
        </Typography>
      </div>
    </div>
    {counter ? (
      <CounterBadge className={styles.counter} counter={counter} />
    ) : null}
  </Link>
);
