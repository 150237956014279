import { api } from './api';
import { apiEventEmitter } from './api-event-emitter';

import Cookies from 'js-cookie';

import { AUTH_COOKIE_NAME } from 'services/constants/auth-cookie-name';
import { MAIN_DOMAIN } from 'services/constants/main-doamin';

export const clearAuthCookies = () => {
  Cookies.remove(AUTH_COOKIE_NAME);

  if (MAIN_DOMAIN) {
    Cookies.remove(AUTH_COOKIE_NAME, {
      domain: MAIN_DOMAIN,
    });
  }
};

export const logout = (): void => {
  api
    .post('v1/user/logout', { abortOn401: false })
    .then(() => {
      clearAuthCookies();
      apiEventEmitter.emit('logout');
    })
    .catch((error) => {
      throw error;
    });
};
